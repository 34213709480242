// import React from 'react'

// import PropTypes from 'prop-types'

// import './announcement.css'

// const Announcement = (props) => {
//   return (
//     <div className={`announcement-announcement ${props.rootClassName} `}>
//       <span className="announcement-title">{props.title}</span>
//       <div className="announcement-button">
//         <a
//           href="https://www.instagram.com/facci.ootd"
//           target="_blank"
//           rel="noreferrer noopener"
//           className="announcement-button-text"
//         >
//           {props.button}
//         </a>
//       </div>
//     </div>
//   )
// }

// Announcement.defaultProps = {
//   title: 'We are launching in late-Sep this year.',
//   rootClassName: '',
//   button: 'Follow our IG to be updated!',
// }

// Announcement.propTypes = {
//   title: PropTypes.string,
//   rootClassName: PropTypes.string,
//   button: PropTypes.string,
// }

// export default Announcement

import React from 'react';
import PropTypes from 'prop-types';
import './announcement.css';

const Announcement = (props) => {
  // Function to handle click on the entire component
  const handleClick = () => {
    window.open("https://www.instagram.com/facci.ootd", "_blank", "noopener,noreferrer");
  };

  return (
    <div className={`announcement-announcement ${props.rootClassName}`} onClick={handleClick}>
      <span className="announcement-title">{props.title}</span>
      <div className="announcement-button">
        {/* Removed the <a> tag but you can keep it if you want the text to be specifically clickable as well */}
        <span className="announcement-button-text">
          {props.button}
        </span>
      </div>
    </div>
  );
};

Announcement.defaultProps = {
  title: 'We are launching in late-Sep this year.',
  rootClassName: '',
  button: 'Follow our IG to be updated!',
};

Announcement.propTypes = {
  title: PropTypes.string,
  rootClassName: PropTypes.string,
  button: PropTypes.string,
};

export default Announcement;